<template>
  <div>
    <el-page-header @back="$router.back()" content="详情页面"></el-page-header>

<!--    <office-u-i-fabric-vue></office-u-i-fabric-vue>-->
  </div>
</template>

<script>
// import OfficeUIFabricVue from 'office-ui-fabric-vue';

// import css style
import 'office-ui-fabric-vue/dist/index.css';

export default {
  name: "ppt",
  components:{
    // OfficeUIFabricVue
  }
}
</script>

<style scoped>

</style>